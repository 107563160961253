import start from '../../../../common/microservices/loaders/order-calculator/with-phones-collector'

import '../lazy-microservices/lazy-microservices'

start(() => import('../order-calculator/render'), 'microservice__new-order__root', {
  lazyStoreManagerLoader: () => import('../../../../common/microservices/lazy-store-manager'),

  phonesCollectorRenderLoader: () => import('./render'),
  phonesCollectorRenderMountPointId: 'microservice__phones-collector__root',

  mediaQuery: '(min-width: 768px)',
})
