import calculator, { Options as CalculatorOptions } from '.'
import type { RenderLoader } from '../types'

interface Options extends CalculatorOptions {
  phonesCollectorRenderLoader: RenderLoader
  phonesCollectorRenderMountPointId: string
}

export default function calculatorWithPhonesCollector(renderLoader: RenderLoader, mountPointId: string, options: Options) {
  const phonesCollectorMountPoint = document.getElementById(options.phonesCollectorRenderMountPointId) as HTMLElement

  let observer!: IntersectionObserver

  // Start calculator
  calculator(renderLoader, mountPointId, {
    lazyStoreManagerLoader: options.lazyStoreManagerLoader,

    mediaQuery: options.mediaQuery,
  })

  // Start phones collector
  if (typeof window.IntersectionObserver !== 'undefined') {
    observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        phonesCollector()
      }
    }, {
      rootMargin: "750px",
      threshold: 1.0,
    })
    observer.observe(phonesCollectorMountPoint)
  } else {
    phonesCollector()
  }

  async function phonesCollector() {
    observer?.disconnect()

    await (await options.lazyStoreManagerLoader()).initializing;

    (await options.phonesCollectorRenderLoader())
      .render(phonesCollectorMountPoint)
  }
}